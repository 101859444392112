<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <side-bar></side-bar>
      <div class="pages-con-wrap">
        <div class="collection-record-bg">
          <div class="collection-record-con">
            <b-container fluid>
              <h2 class="page-title">我的收藏</h2>
              <el-tabs v-model="activeTab" @tab-click="handleClick">
                <el-tab-pane label="商品收藏" name="goods">
                  <b-row class="b-row" v-if="goodsList.collections && goodsList.collections.length > 0">
                    <b-col class="b-col" xs="12" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in goodsList.collections" :key="index">
                      <el-card shadow="hover">
                        <div class="goods-item-wrap">
                          <div class="pic" @click="toGoodsDetail(item)">
                            <img :src="item.file_path" :alt="item.goods_name">
                          </div>
                          <div class="detail">
                            <div class="name" @click="toGoodsDetail(item)">{{item.goods_name}}</div>
                            <div class="price-wrap">
                              <span class="price">￥{{item.sale_price}}</span>
                              <span class="sold">已售{{item.sale_count}}件</span>
                            </div>
                          </div>
                        </div>
                      </el-card>
                    </b-col>
                  </b-row>
                </el-tab-pane>
                <el-tab-pane label="品牌收藏" name="brand">
                  <b-row class="b-row" v-if="brandList.collections && brandList.collections.length > 0">
                    <b-col class="b-col" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in brandList.collections" :key="index">
                      <el-card shadow="hover">
                        <div class="brand-item-wrap">
                          <div class="pic" @click="toBrand(item)">
                            <img :src="item.brand_cover" :alt="item.brand_name">
                          </div>
                          <div class="detail">
                            <div class="brand-logo-wrap">
                              <div class="brand-logo">
                                <img :src="item.brand_logo" :alt="item.brand_name">
                              </div>
                            </div>
                            <div class="brand-name">{{item.brand_name}}</div>
                          </div>
                        </div>
                      </el-card>
                    </b-col>
                  </b-row>
                </el-tab-pane>
                <!-- <el-tab-pane label="模型收藏" name="model">
                  <b-row class="b-row" v-if="modelList.list && modelList.list.length > 0">
                    <b-col class="b-col" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in modelList.list" :key="index">
                      <el-card shadow="hover">
                        <div class="model-item-wrap">
                          <div class="pic" @click="to3DModelDetail(item)">
                            <img :src="item.img" :alt="item.modelName">
                          </div>
                          <div class="detail">
                            <div class="model-name" @click="to3DModelDetail(item)">{{item.modelName}}</div>
                            <div class="model-params">
                              <div class="model-l">ID:{{item.modelId}} </div>
                              <div class="model-r">
                                <span>
                                  <i class="el-icon-view"></i>{{item.viewTotal ? item.viewTotal : 0}}
                                </span>
                                <span>
                                  <i class="el-icon-download"></i>{{item.downloadTotal ? item.downloadTotal : 0}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-card>
                    </b-col>
                  </b-row>
                </el-tab-pane>
                <el-tab-pane label="二维方案收藏" name="scheme">
                  <b-row class="b-row" v-if="schemeList && schemeList.length > 0">
                    <b-col class="b-col" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in schemeList" :key="index">
                      <el-card shadow="hover">
                        <div class="scheme-item-wrap">
                          <div class="pic" @click="to2DDetail(item)">
                            <img :src="item.coverUri" :alt="item.name">
                          </div>
                          <div class="detail">
                            <div class="scheme-name">{{item.name}}</div>
                            <div class="scheme-params">
                              <div class="designer-avatar-wrap">
                                <div class="designer-avatar">
                                  <img v-if="item.headImgUri !='' && item.headImgUri !=null" :src="item.headImgUri" :alt="item.nickName">
                                  <img v-else src="~@/../public/img/person.431c96c3.png"/>
                                </div>
                                <span>{{(item.nickName != null && item.nickName != '' && item.nickName !='null') ? item.nickName : $t('portalTitle')}}</span>
                              </div>
                              <div class="scheme-view">
                                <i class="el-icon-view"></i>
                                <span>{{item.viewNum}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-card>  
                    </b-col>
                  </b-row>
                </el-tab-pane> -->
              </el-tabs>
            </b-container>
          </div>
        </div>
      </div>
    </div>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import sideBar from "@/components/userCenterSidebar";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headNav,
      sideBar,
      footerNav
    },
    data(){
      return {
        token: null,
        activeTab: 'goods',
        goodsList: [],
        brandList: [],
        modelList: [],
        schemeList: []
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getGoodsCollectionList();
      },
      // 会员商品收藏列表 
      getGoodsCollectionList(){
        this.$api.goodsCollectionList({
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.goodsList = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 会员品牌收藏列表 
      getBandCollectionList(){
        this.$api.bandCollectionList({
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.brandList = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 会员模型收藏列表
      getModelCollectionList(){
        this.$api.modelCollectionList({
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.modelList = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 会员二维方案收藏列表 
      getTwoDimensionCollect(){
        this.$api.twoDimensionCollect({
          token: this.token
        }).then(res => {
          if(res.code == 200){
            this.schemeList = res.data;
          }
        }).catch(err => console.error(err))
      },
      handleClick(tab){
        switch(tab.name){
          case 'goods': this.getGoodsCollectionList();
          break;
          case 'brand': this.getBandCollectionList();
          break;
          case 'model': this.getModelCollectionList();
          break;
          case 'scheme': this.getTwoDimensionCollect();
          break;
        }
      },
      // 跳转到商品详情页
      toGoodsDetail(obj){
        dump.link({
          type: 3,
          link: "/details",
          params: {
            good_id: obj.goods_id
          }
        })
      },
      // 跳转到品牌页
      toBrand(obj){
        dump.link({
          type: 2,
          link: `brand_in.html?brand_id=${obj.brand_id}&brand_type=${obj.brand_type}`,
        })
      },
      // 跳转到3D模型详情
      to3DModelDetail(obj){
        dump.link({
          type: 3,
          link: "/model3Ddetail",
          params: {
            id: obj.modelId
          }
        })
      },
      // 跳转到二维方案详情
      to2DDetail(item) {
        item.type = 3;
        item.link = "/twodimensionalprogramme";
        item.params = {
          id: item.id
        };
        dump.link(item);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/collectionRecord/collectionRecord";
</style>
